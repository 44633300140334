import { useEffect, useRef, useState } from "react";
import SearchIndexProvider from "../../service/SearchIndexProvider";
import { Employee } from "../../types/ganttChart";
import {
  addFilter,
  addItemToSearchHistory,
  clearFilters,
  clearHistory,
  SearchItem,
  setHighlightedTasks,
} from "../../store/search";
import ColouredRoundWrapper from "../UI/ColouredRoundWrapper";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import wrapperStyles from "../../styles/UI/wrappers.module.css";
import searchStyles from "../../styles/search.module.css";
import labels from "../../utils/labels";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import SearchResult from "./SearchResult";
import GreyOpacityText from "../UI/textFields/GreyOpacityText";
import SearchItemChip from "./SearchItemPill";
import SearchIndex from "../../service/SearchIndex";

const SearchBar = ({ employees }: { employees: Employee[] }) => {
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState<SearchItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const searchIndex = new SearchIndex(employees);
  const dispatch = useAppDispatch();
  const searchHistory = useAppSelector((state) => state.searchSlice.history);
  const appliedFilters = useAppSelector(
    (state) => state.searchSlice.appliedFilters,
  );

  useEffect(() => {
    const fetchSuggestions = () => {
      if (input.length > 0) {
        const results = searchIndex.suggest(input, appliedFilters);
        setSuggestions(results);
        setIsOpen(results.length > 0);
      } else {
        setSuggestions([]);
        setIsOpen(false);
      }
    };

    fetchSuggestions();
  }, [input]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSuggestionClick = (suggestion: SearchItem) => {
    dispatch(setHighlightedTasks(suggestion.ids));
    dispatch(addFilter(suggestion));
    dispatch(addItemToSearchHistory(suggestion));
    setInput("");
    setIsOpen(false);
  };

  return (
    <div className="relative" style={{ width: 640 }}>
      <ColouredRoundWrapper bgColor="var(--col-grey-1)" padding width={"100%"}>
        <SearchIcon />
        <div className="flex flex-row">
          {searchHistory.map((searchItem, index) => (
            <SearchItemChip searchResult={searchItem} key={index} />
          ))}
        </div>
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder={labels.searchPrompt}
          className={`bg-transparent ${searchStyles.searchInputField} w-[100%] focus:outline-none`}
          style={{ height: "100%" }}
        />
        <div
          className="min-w-[102px] cursor-pointer"
          onClick={() => {
            setInput("");
            dispatch(clearFilters());
            dispatch(clearHistory());
            dispatch(setHighlightedTasks([]));
          }}
        >
          <GreyOpacityText size={"SMALL"}>Nulstil søgning</GreyOpacityText>
        </div>
      </ColouredRoundWrapper>
      {isOpen && suggestions.length > 0 && (
        <ul
          ref={dropdownRef}
          className={wrapperStyles.colouredRoundWrapper}
          style={{
            position: "absolute",
            zIndex: 9999,
            width: "100%",
            border: "1px solid #e2e8f0",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
            overflowY: "auto",
            maxHeight: 300,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            padding: 30,
            gap: 20,
          }}
        >
          <div className="flex w-[100%]">
            <GreyOpacityText size={"MEDIUM"}>Resultater</GreyOpacityText>
          </div>

          {suggestions.map((suggestion, index) => (
            //Use SearchResult.tsx - I will style it later
            <SearchResult
              key={index}
              result={suggestion}
              onClick={() => handleSuggestionClick(suggestion)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
