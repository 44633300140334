import ganttChartService from "../../../service/ganttChartService";
import { useAppSelector } from "../../../store/hooks";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import VerticalGanttChartTaskWrapper from "./VerticalGanttChartTaskWrapper";
import VerticalWorkTask from "./VerticalWorkTask";
import { Fragment } from "react";

const VerticalGanttChartContent = () => {
  // selectedGanttChartTab
  const { verticalColumnWidth, ganttChartData } = 
    useAppSelector((state) => state.ganttChartSlice);
  const { timelineRange, pixelPerMinute } = useAppSelector(
    (state) => state.timelineSlice,
  );

  return (
    <>
      {/* Todo: rewrite in order to match timepoints proper after discussion with Morten. */}
      {/* Unplanned tasks */}
      {ganttChartData &&
        timelineRange &&
        ganttChartData!.generalGanttChartInfo.unplannedTasks.workTasks.map(
          (task, index) => (
            <Fragment key={"nexus-unplanned-" + index}>
              <VerticalWorkTask
                key={"nexus-unplanned" + index}
                xPosition={ganttChartVerticalValues.verticalViewHeaderColumn}
                task={task}
                yPosition={ganttChartVerticalValues.findTaskYPosition(
                  timelineRange!,
                  pixelPerMinute,
                  task.preferredEarliestStart,
                )}
                width={ganttChartVerticalValues.getTaskWidth(
                  verticalColumnWidth,
                )}
                height={
                  ganttChartService.timeDifferenceInMinutes(
                    task.preferredEarliestStart,
                    task.preferredLatestEnd,
                  ) * pixelPerMinute
                }
              />
            </Fragment>
          ),
        )}
      {/* Todo : Is this needed then? */}
      {/* {ganttChartData &&
        selectedGanttChartTab === "Pondoo" &&
        ganttChartData.pondooSpecific &&
        ganttChartData.pondooSpecific.unplannedTasks.workTasks.map(
          (task, index) => (
            <VerticalWorkTask
              key={"pondoo-unplanned" + index}
              xPosition={ganttChartVerticalValues.verticalViewHeaderColumn}
              task={task}
              yPosition={ganttChartVerticalValues.findTaskYPosition(
                timelineRange!,
                pixelPerMinute,
                task.earliestStart
              )}
              width={ganttChartVerticalValues.getTaskWidth(verticalColumnWidth)}
            />
          )
        )} */}
      {/* Planned Tasks */}
      {ganttChartData &&
        timelineRange &&
        ganttChartData.employees.map((employee, index) => (
          <Fragment key={index}>
            <VerticalGanttChartTaskWrapper
              key={`tasks-${index}`}
              employee={employee}
              xPosition={
                verticalColumnWidth >=
                ganttChartVerticalValues.verticalViewMinColumnWidth
                  ? ganttChartVerticalValues.verticalViewHeaderColumn +
                    (index + 1) * verticalColumnWidth
                  : ganttChartVerticalValues.verticalViewHeaderColumn +
                    (index + 1) *
                      ganttChartVerticalValues.verticalViewMinColumnWidth
              }
            />
          </Fragment>
        ))}
    </>
  );
};

export default VerticalGanttChartContent;
