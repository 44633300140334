class labels {
  static adress = "Adresse";
  static citizens = "Borgere";
  static citizen = "Borger";
  static visit = "Besøg";
  static visitHours = "Besøgstid";
  static visitDetails = "Besøgdetaljer";
  static employees = "Medarbejdere";
  static employee = "Medarbejder";
  static hours = "Timer";
  static details = "Detaljer";
  static dependencies = "Afhængigheder";
  static task = "Ydelse";
  static tasks = "Ydelser";
  static priority = "Prioritet";
  static citizenDetails = "Borgerdetaljer";
  static skillRequirement = "Kompetencekrav";
  static skills = "Kompetencer";
  static time = "Tid";
  static expenses = "Omkostninger";
  static citizenService = "Borgerservice";
  static affinity = "Kontinuitet";
  static filter = "Filtre";
  static AIMissing = "AI planlægning mangler";
  static AICompleted = "AI planlægning er færdig";
  static search = "Søg";
  static choosePeriod = "Vælg periode";
  static locked = "Låst på medarbejder";
  static score = "Score";
  static competencies = "kompetencer";
  static assignedEmployee = "Tildelt medarbejder";
  static preferences = "Præferencer";
  static high = "Høj";
  static normal = "Normal";
  static searchPrompt ="Søg borger eller ydelse"

  // Task Dependencies
  static isDependencyOfTitle = "Besøget har følgende tilknyttede besøg";
  static dependsOnTitle =  "Besøget er tilknyttet følgende primære besøg";
  static startTimeColumnLabel = "Opstart";
  static endTimeColumnLabel = "Slut"
  // voolations
  static violationSkill =
    "Medarbejderen opfylder ikke alle kompetencekrav på opgaven";
  static violationDependency = "En tids sammenkædning er ikke overholdt";
  static violationDependencySameEmployee =
    "En samme medarbejder sammenkædning er ikke overholdt";
  static violationOverdue = "Det tilladte sluttidspunkt er ikke overholdt";
  static violationOvertime = "Medarbejderen har for mange timer";
  static violationTravelTotalDistance =
    "Medarbejderen har for lang transport over hele dagen";
  static violationTravelTripDistance =
    "Medarbejderen har for lang transport på en enkelt tur";
  static violationTravelFirstLastTripDistance =
    "Medarbejderen har for lang transport på første eller sidste tur";

  // buttons
  static sinceNexusData = "Siden Nexus-data";
  static sendNexusData = "Send plan til Nexus";
  static UpdatePlan = "Opdater planlægning";

  // login page
  static login = "Log ind";

  static hoverEmployee = "Medarbejder";
  static hoverTasks = "Ydelser";

  static pondooCompleted = (hours: number) => {
    return `Pondoo plan opdateret ${hours} ${hours > 1 ? "timer" : "time"} siden`;
  };
  static pondooNotUpdated = "Pondoo plan ikke opdateret";
  static pondooMissing = "Pondoo plan mangler";
  static pondooStatusError = "Pondoo plan fejlede";
  static pondooRunning = "Kører...";
  static tryAgain = "Prøv igen";
  static update = "Opdater";
}

export default labels;
