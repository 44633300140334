import BlackRoundWrapper from '../UI/ColouredRoundWrapper';
import CirclularStatusIcon from '../UI/CirclularStatusIcon';
import labels from '../../utils/labels';
import StandardButton from '../UI/buttons/StandardButton';
import podooStatusBarStyles from '../../styles/pondooStatusBar.module.css';
import { ReactComponent as StarsIcon } from '../../assets/icons/white-stars.svg';
import { ReactComponent as XIcon } from '../../assets/icons/x.svg';
import { useAppSelector } from '../../store/hooks';
import { useEffect, useState } from 'react';
import { JobStatus, PondooJobStatus } from '../../types/ganttChart';
import IconButtonComponent from '../UI/buttons/IconButton';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

type PondooStatusBarObject = {
	title: string;
	status: PondooJobStatus;
	buttonTitle?: string;
};

const PondooPlanStausBar = () => {
	const { pondooStatus, pondooStatusError } = useAppSelector((state) => state.ganttChartSlice);
	const updatePlan = () => {
		console.log('Update plan');
	};
	const getDifferenceInHours = () => {
		return Math.floor(
			(new Date(pondooStatus!.data.completedDateTime as string).getTime() - new Date().getTime()) /
				(1000 * 60 * 60)
		);
	};

	const getStatusBasedOnUpdateDate = (): JobStatus | 'OUTDATED' => {
		if (getDifferenceInHours() > 1) return 'OUTDATED';
		else return 'Completed';
	};

	const [pondooStatusBarObject, setPondooStatusBarObject] = useState<PondooStatusBarObject>();

	useEffect(() => {
		if (pondooStatusError) {
			setPondooStatusBarObject({
				title: labels.pondooStatusError,
				buttonTitle: labels.tryAgain,
				status: 'Failed',
			});
		} else if (!pondooStatus || !pondooStatus.data) {
			setPondooStatusBarObject({
				title: labels.pondooMissing,
				buttonTitle: labels.pondooMissing,
				status: 'MISSING',
			});
		} else {
			const jobStatus = pondooStatus.data.jobStatus;
			if (jobStatus === 'Completed') {
				const newStatus = getStatusBasedOnUpdateDate();
				setPondooStatusBarObject({
					title: labels.pondooCompleted(getDifferenceInHours()),
					buttonTitle: newStatus === 'OUTDATED' ? labels.update : undefined,
					status: newStatus,
				});
			} else if (jobStatus === 'Failed') {
				setPondooStatusBarObject({
					title: labels.pondooStatusError,
					buttonTitle: labels.tryAgain,
					status: jobStatus,
				});
			} else if (jobStatus === 'NotScheduled' || jobStatus === 'Scheduled' || jobStatus === 'Running') {
				setPondooStatusBarObject({
					title: labels.pondooRunning,
					status: jobStatus,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pondooStatus, pondooStatusError]);

	const stopOptimization = () => {};

	return (
		<BlackRoundWrapper
			bgColor="var(--col-black)"
			height="60px"
			width="fit-content"
			classes={`${podooStatusBarStyles.nexusPlanInfo} absolute bottom-[--spacing-xl] right-[--spacing-xl]`}
		>
			{pondooStatusBarObject && (
				<>
					<span className="flex items-center gap-[10px] min-w-[fit-content]">
						<CirclularStatusIcon status={pondooStatusBarObject?.status} />
						{pondooStatusBarObject.title}
					</span>
					{pondooStatusBarObject.buttonTitle && (
						<StandardButton onClick={updatePlan} variant="WHITE" icon={<StarsIcon />}>
							{pondooStatusBarObject.buttonTitle}
						</StandardButton>
					)}
					{(pondooStatusBarObject.status === 'Running' ||
						pondooStatusBarObject.status === 'NotScheduled' ||
						pondooStatusBarObject.status === 'Scheduled') && (
						<div className="flex items-center">
							<Box sx={{ width: '200px' }}>
								<LinearProgress
									sx={{
										backgroundColor: 'white',
										borderRadius: '30px',
										height: '8px',
										'& .MuiLinearProgress-bar': {
											backgroundColor: 'var(--col-lavendar-dark)',
										},
									}}
								/>
							</Box>
							<IconButtonComponent
								id="x"
								icon={<XIcon />}
								onClick={stopOptimization}
							></IconButtonComponent>
						</div>
					)}
				</>
			)}
		</BlackRoundWrapper>
	);
};

export default PondooPlanStausBar;
